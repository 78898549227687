import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useTimer } from "react-use-precision-timer";
import React, {useEffect } from 'react';

import general from './../../static/json/general.json';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');


function Square({value, id, onSquareClick, tileset}) {
	var classes = (value[3] == 1) ? "wrongSquare square" : "square";
	var tiles = "";

	function getImage(num) {
		var type = ["a", "b", "c"];
		if(value[num] > 0) {
			return <div className="image"><img src={"/images/tilesets/" + tileset + "/" + type[num] + value[num] + ".png"} alt="Tile"/></div>;
		} else {
			return "";
		}
	}

	function getBackground() {
		return <div className="image"><img src={"/images/tilesets/" + tileset + "/background.png"} alt="X"/></div>;
	}

    return (
        <td className={classes} onClick={onSquareClick}>
            { getBackground() }
            { getImage(0) }
            { getImage(1) }
            { getImage(2) }


        </td>
    );
}


function getRandomStart() {

	var board = Array(26).fill([0,0,0]);
	var boardSmall = [
		[1,1,1], [1,1,5], [1,2,1], [1,2,2], [1,3,2], [1,3,3],
		[2,1,4], [2,2,5], [2,3,1], [3,1,2], [3,2,3], [3,3,4], [3,3,1]
	];
	for(var i = 0; i < 13; i++) {
    	board[i] = boardSmall[i].slice();
    	board[i+13] = boardSmall[i].slice();
    }
    board.sort(() => Math.random() - 0.5);
    //board[25] = [0,0,0];
	return board;
}

export default function Unpaired({onTilesFinish, tileset}) {
    const [squares, setSquares] = useState(getRandomStart);

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [longestCombo, setLongestCombo] = useState(0);
    const [tilesLeft, setTilesLeft] = useState(48);

    const [currentTime, setCurrentTime] = useState(0);
    const [time, setTime] = useState(0);


	useEffect(() => {
        let interval = null;

        interval = setInterval(() => {
            setTime(prevTime => prevTime + 10);
        }, 10);

        return () => clearInterval(interval);
      });


	function handleClick(clicked) {


        const nextSquares = squares.slice();
        if(squares[clicked][3] == 1) {
            console.log("X!");

        } else if(squares[clicked][0] == squares[25][0] && squares[clicked][1] == squares[25][1] && squares[clicked][2] == squares[25][2]) {
            console.log("Win!");
            onTilesFinish(time/1000);

        } else {
            setTime(prevTime => prevTime + 30000);
            nextSquares[clicked][3] = 1;
            var found = nextSquares.findIndex((element) => element[0] == nextSquares[clicked][0] && element[1] == nextSquares[clicked][1] && element[2] == nextSquares[clicked][2]);
			nextSquares[found][3] = 1;
            found = nextSquares.findLastIndex((element) => element[0] == nextSquares[clicked][0] && element[1] == nextSquares[clicked][1] && element[2] == nextSquares[clicked][2]);
			nextSquares[found][3] = 1;
            setSquares(nextSquares);
            console.log("Lose!");
        }


    }

	const rows = [0,1,2,3,4];
	const columns = [0,1,2,3,4];

	function lineCode(num) {
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square tileset={tileset} key={5*num+column} id={5*num+column} value={squares[5*num+column]}
					onSquareClick={() => handleClick(5*num+column)} />
				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("moveInfoUnpaired");
	}

	const boardCode = (<div className="fullBoard">

			<Row>
				<Row className="instruction">
                    <div className="col2"><p className="instructionText"> {getInstruction()} </p></div>
                </Row>

				<Col sm={9} className="gameBoardCol">
					<Table className="gameBoardNotAnimated"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1"><br/><h1></h1></div>
					<div className="col1">{ "Time" }<br/><h1>{ (time/1000).toFixed(1) }</h1></div>
					<br/>
					<Button variant="success" className="small-btn" onClick={() =>  window.location.reload()}>RESTART</Button>
					</Row>
				</Col>
			</Row>

	    </div>
	);

    return boardCode;
}

