import React from 'react';

import '../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import tile1 from '../static/tile1.png'
import tile2 from '../static/tile2.png'
import tile3 from '../static/tile3.png'

import Tiles from './games/Tiles';
import TilesFour from './games/TilesFour';
import Pairs from './games/Pairs';
import Infinite from './games/Infinite';
import Timed from './games/Timed';
import Same from './games/Same';
import Segments from './games/Segments';
import Neighbours from './games/Neighbours';
import Lines from './games/Lines';
import Unpaired from './games/Unpaired';
import Swapoku from './games/Swapoku';
import Shuzzle from './games/Shuzzle';
import Pipes from './games/Pipes';

import Comments from './Comments';

import { Helmet } from 'react-helmet';

import Shares from './Shares';
import Minigames from './games/Minigames';
import Moregames from './games/Moregames';
import Badges from '../library/Badges';

import GoogleAd from './GoogleAd';

import levels_lines from '../static/json/levels_lines.json';
import general from '../static/json/general.json';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');



function getResult(game, tileset, param) {
	return localStorage.getItem(game + "-" + tileset + "-" + param);
}

function setResult(game, tileset, param, result) {
	return localStorage.setItem(game + "-" + tileset + "-" + param, result);
}


function Main({game, finishGame, tileset, lang}) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const fourLayersSets = general["fourlayers"];

	function codeForGame() {
		if(game == "tiles") {
			if(fourLayersSets.includes(tileset)) {
				return (<TilesFour onTilesFinish={finishGame} tileset={tileset}/>);
			} else {
				return (<Tiles onTilesFinish={finishGame} tileset={tileset}/>);
			}
		}
		if(game == "pairs") {
			return (<Pairs onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "infinite") {
			return (<Infinite onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "timed") {
			return (<Timed onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "same") {
			return (<Same onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "segments") {
			return (<Segments onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "neighbours") {
			return (<Neighbours onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "lines") {
			return (<Lines onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "unpaired") {
			return (<Unpaired onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "swapoku") {
			return (<Swapoku onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "shuzzle") {
			return (<Shuzzle onTilesFinish={finishGame} tileset={tileset}/>);
		}
		if(game == "pipes") {
			return (<Pipes onTilesFinish={finishGame} tileset={tileset}/>);
		}
	}


	var classNamePart = "mainPart";
	if(game == "tiles" && fourLayersSets.includes(tileset)) {
		classNamePart = "mainPartSpecial";
	}

    return (
        <>
	        <div className={classNamePart} >
		        <Container>
		            <GoogleAd type="top"/>
		            <Row>
		                <div className="game">
			                {codeForGame()}
			                <br/>

			            </div>
		            </Row>
		            <GoogleAd type="bottom"/>
		        </Container>
	        </div>
	        <br/><br/>
			<Comments/>
	        <Minigames/>
        </>
      );
}




function Game({game, lang, tileset}) {

	const { t } = useTranslation();
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    function finishGame(result, image="") {

		var best = getResult(game, tileset, "best");

		if(best == null) {
			var finishedSoFar = 1;
			const tilesgames = ["tiles", "tilesbig", "pairs", "infinite", "timed", "unpaired"];
            var listName = tilesgames.includes(game) ? "waiting" : game;

			const newTilesets = general[listName];
			for(var i = 0; i < newTilesets.length; i++) {
				var ts = newTilesets[i]["name"];
				if(getResult(game, ts, "best") != null) {
					finishedSoFar += 1;
				}
			}

			ReactGA.event({
	             category: 'Game',
	             action: 'FinishedGames_' + game,
	             value: finishedSoFar
	        });
		}

        setResult(game, tileset, "last", result);
        setResult(game, tileset, "image", image);

		if(getResult(game, tileset, "best") == null) {
			setResult(game, tileset, "best", result);
		}

		if(game == "neighbours" || game == "tiles" || game == "pairs" || game == "infinite" || game == "timed") {
			if(getResult(game, tileset, "best") < result) {
				setResult(game, tileset, "best", result);
			}
		} else {
			if(getResult(game, tileset, "best") > result) {
            	setResult(game, tileset, "best", result);
            }
		}


        var today = new Date();
		var dateToday = today.getFullYear() + "-" + (today.getMonth()+1) + "-" + today.getDate();

        var todayBest = getResult(game, tileset, dateToday);
        if(todayBest == null) {
            var allDates = getResult(game, tileset, "dates");
            if(allDates === null) {
                allDates = dateToday;
            } else {
                allDates = allDates + " " + dateToday;
            }
            setResult(game, tileset, "dates", allDates);
            setResult(game, tileset, dateToday, result);
        }

        if(game == "tiles" || game == "pairs") {
			if(todayBest < result) {
				setResult(game, tileset, dateToday, result);
			}
		} else {
			if(todayBest > result) {
            	setResult(game, tileset, dateToday, result);
            }
		}


        ReactGA.event({
            category: 'Game',
            action: 'FinishGame',
            value: result
        });

		//setCurrentView("finish");
		var link = "/" + lang + "/" + game + "/" + tileset + "/result";
		window.location.href = link;
	}


	function tilesetToUpper({tileset}) {
		const titles = {
			"tiles": "TILES",
        	"infinite": "INFINITE TILES",
        	"same": "SAME GAME",
        };
        if(tileset in titles) {
        	return titles[tileset];
        } else {
			return tileset.toUpperCase();
		}
	}


	const title = "Unlimited Tiles - " + ((game == "tiles") ? t("title") :  t(game + "Title")) + " - " + tilesetToUpper({tileset});
	const description = "";


	return (
		<>
			<Helmet>
                <title>{title}</title>
                <link rel="canonical" href={"https://www.unlimited-tiles.com/" + lang + "/" + game + "/" + tileset} data-react-helmet="true" />
                <meta property="og:url" content={"https://www.unlimited-tiles.com/" + lang + "/" + game + "/" + tileset }  data-react-helmet="true" />
                <meta property="og:image" content={"https://www.unlimited-tiles.com/images/games/" + game + "/default.png"} />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={title}/>


            </Helmet>

			<Main game={game} finishGame={finishGame} tileset={tileset} lang={lang}/>


		</>

	);
};

export default Game;
