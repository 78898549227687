import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import ReactGA from 'react-ga4';
import Minigames from './games/Minigames';
import Badges from '../library/Badges';
import Comments from './Comments';
import '../static/css/tilesets.css';
import GoogleAd from './GoogleAd';

import levels_lines from '../static/json/levels_lines.json';
import levels_shuzzle from '../static/json/levels_shuzzle.json';
import { getStars } from '../library/stars.js';
import { getLevels } from '../library/levels.js';

import general from '../static/json/general.json';
import badgesJson from '../static/json/badges.json';

function bigName(gameName) {
		const names = {
        	"lines": "COLOR LINES",
        	"same": "SAME GAME"
        };
        if(gameName in names) {
        	return names[gameName];
        } else {
			return gameName.toUpperCase();
		}
}



function Tileset({game, tileset, stars, date}) {

	const navigate = useNavigate();
	const { t } = useTranslation();
	const detectedLanguage = window.location.pathname.split('/')[1];

	function bestScore() {
		var best = localStorage.getItem(game + "-" + tileset + "-best");

		if(best != undefined) {
			return (<>{t("bestSoFar")}: {best}</>)
		} else {
			return (<>  </>)
		}
	}

	function getUrl({game, tileset}) {
		return ("/" + detectedLanguage + "/" + game + "/" + tileset);
	}

	function playGame({game, tileset}) {
	    ReactGA.event({
	        category: 'Game',
	        action: 'StartGame'
	    });
	    window.location.href = getUrl(game, tileset);
    }

	function playTileset({available, game, tileset}) {
		if(available) {
			ReactGA.event({
		        category: 'Game',
		        action: 'StartGame'
		    });
		    window.location.href = getUrl({game, tileset});
		}
    }

	function textToUpper({tileset}) {
		const titles = {
        	"rubik": "RUBIK HARD",
        	"rubik3": "RUBIK EASY",
        	"bwcircles": "B&W CIRCLES",
        	"nightgarden": "NIGHT GARDEN",
        	"blacksquares": "BLACK SQUARES",
        	"whitelines": "WHITE LINES",
        	"mondrian": "PIET MONDRIAN",
        	"triumphalarch": "ARCH"
        };
        if(tileset in titles) {
        	return titles[tileset];
        } else {
			return tileset.toUpperCase();
		}
	}

	function getImage(available, game, tileset) {
		if(game === "lines") {

			if(available) {
		        return(<Card.Img variant="top" className="squareTilesetImage" src={"/images/games/" + game + "/" + tileset + ".png"}  /> );
		    } else {
		        return(<Card.Img variant="top" className="squareTilesetImage newTileset" src={"/images/games/" + game + "/" + tileset + ".png"}  /> );
	        }
		}

		var coverTileset = (<></>);
		if(game == "timed" || game == "infinite" || game == "pairs") {
			coverTileset = (<Card.Img style={{"position": "absolute"}} variant="top" src={"/images/games/" + game + "/cover.png"}  />);
		}
		if(game == "unpaired" || game == "timed" || game == "infinite"|| game == "pairs" ) {game="tiles";}
		if(available) {
	        return (<> {coverTileset}
	        <Card.Img alt={game + " game " + tileset } variant="top" src={"/images/games/" + game + "/" + tileset + ".png"}  /></> );

	    } else {
	        return (<Card.Img className="newTileset" variant="top" src={"/images/games/" + game + "/" + tileset + ".png"}  />);
        }
	}

	function getStarsCode() {
		return (
			<div>
				{(stars >= 1) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
				{(stars >= 2) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
				{(stars >= 3) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}

			</div>
		);
	}

	function getDifficulty(game, level) {
		if(levels_shuzzle["hard"].includes(Number(level))) {return "HARD";}
		else if(levels_shuzzle["medium"].includes(Number(level))) {return "MEDIUM";}
		else { return "EASY";}
	}


	if(date === "") {
		available = true;
	} else {
		var available = false;
        const todayDate = new Date();
        const dateDate = new Date(date);
		if(dateDate <= todayDate) {
			available = true;
		}
	}
	var color = "white";
	const fourLayersSets = general["fourlayers"];
	if(game == "tiles" && fourLayersSets.includes(tileset)) {
		color = "#ccdec4";
	}

    return (
        (<Col className='tilesetContainer'>
            <Card style={{"background-color": color}} onClick={() => playTileset({available, game, tileset})}
                className={game == "swapoku" || game == "shuzzle"  ? "tilesetCard bigTilesetCard" : "tilesetCard"}>
                {
                    getImage(available, game, tileset)
                }

                <Card.Body>
                    {
                        game == "swapoku"
                        ? <Card.Title>{date}</Card.Title>
                        : game == "shuzzle"
	                        ? <Card.Title>Puzzle {tileset}</Card.Title>
	                        : <><Card.Title>{textToUpper({tileset})}</Card.Title>
	                            <Card.Text className="tilesetDate">{date}</Card.Text></>
                    }
                    { getStarsCode() }
                    <Card.Text>{bestScore()}</Card.Text>
                    {
                        available
                        ? <Button className="small-btn" variant="primary"
                            onClick={() => playGame({game, tileset})}>{t("play")}</Button>
                        : <>Level available from<br/> {date} </>
					}
                </Card.Body>
            </Card>
        </Col>)

    );
}

function getTilesets(game) {

	var ts, tilesets = [];
	const tilesgames = ["tiles", "tilesbig", "pairs", "infinite", "timed", "unpaired"];
	var listName = tilesgames.includes(game) ? "waiting" : game;

	const todayDate = new Date();
	const tomorrowDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
	var dateDate = new Date();

	console.log(todayDate);

	for(var i = 0; i < general[listName].length; i++) {
		ts = general[listName][i];
		dateDate = new Date(ts["date"]);
		if(dateDate <= tomorrowDate) {
            tilesets.push(ts);
        }
	}
	return tilesets;
}

function getTilesetsAvailable(game) {

	var ts, tilesets = [];
	const tilesgames = ["tiles", "tilesbig", "pairs", "infinite", "timed", "unpaired"];
	var listName = tilesgames.includes(game) ? "waiting" : game;

	const todayDate = new Date();
	var dateDate = new Date();

	for(var i = 0; i < general[listName].length; i++) {
		ts = general[listName][i];
		dateDate = new Date(ts["date"]);
		if(dateDate <= todayDate) {
            tilesets.push(ts);
        }
	}
	return tilesets;

}



const Tilesets = ({game, language}) => {
	const { t } = useTranslation();
	var tilesets = getTilesets(game);
	var tilesetsAvailable = getTilesetsAvailable(game);

	while(tilesetsAvailable.length + 1 < tilesets.length) {
		tilesets.shift();
	}
	const [linkCopied, setLinkCopied] = useState(false);

	let detectedLanguage = window.location.pathname.split('/')[1];
	if(detectedLanguage === "") {
		detectedLanguage = "en";
	}

	function gameToUpper({game}) {
		const titles = {
			"tiles": "TILES",
        	"infinite": "INFINITE TILES",
        	"same": "SAME GAME",
        };
        if(game in titles) {
        	return titles[game];
        } else {
			return game.toUpperCase()
		}
	}


	function countStars(game) {
		var stars = 0;
		var tsets = getTilesets(game);

		for(var i = 0; i < tsets.length; i++) {
			var tset = tsets[i];
			var tileset = tset["name"];
			stars += getStars(tileset, game);
		}
		return stars;
	}

	function getStarsTileset({tileset, game}) {
		tileset = tileset["name"];
		return getStars(tileset, game);
	}

	function getInstruction(game) {
		var inst = {
			"tiles": "",
			//"tiles": "Look for tiles that have a common element. Score as many points as possible.",
			"pairs": "Find pairs of identical tiles.",
			"infinite": "Infinite version - tiles recreate when cleared.",
			"lines": "Draw the goal image in as few moves as possible.",
			"same": "Clear the board in as few moves as possible.",
			"unpaired": "Find the square that doesn't have a pair!",
			"timed": "You have 100 seconds to create the longest combo."
		}
		return inst[game];
	}

	function copyLink({game, setLinkCopied}) {
		ReactGA.event({
	        category: 'Game',
	        action: 'ShareResult'
	    });
	    setLinkCopied(true);
	    var link = "https://www.unlimited-tiles.com/";
	    var textToShare = "Hi! My score in Unlimited Tiles is ⭐ " + countStars(game) + "/" + (3*tilesetsAvailable.length) + ". Can you beat me?";
	    var image = "";
	    for(var i = 0; i < tilesetsAvailable.length; i++) {
	        var tileset = tilesetsAvailable[i]["name"];
        	var best = localStorage.getItem(game + "-" + tileset + "-best");
        	var maxx = 45;
        	const fourLayersSets = general["fourlayers"];
        	if(fourLayersSets.includes(tileset)) { maxx = 60; }

	        if(best == maxx) {image += "🟩";}
	        else if(best >= maxx-5) {image += "🟨";}
	        else if(best == null) {image += "⬜";}
	        else {image += "🟧"; }
	    }
	    navigator.clipboard.writeText(textToShare + "\n" + image +  "\n\n" + link);

	}


	const tilesetHtml = (
        <div>

	        <Helmet>
	            <title>Unlimited Tiles - {gameToUpper({game})} - Available Tilesets</title>
	            <meta name="description" content={"Choose your favorite tileset and play " + game} />
	            <link rel="canonical" href={"https://www.unlimited-tiles.com/" + detectedLanguage + "/" + game + "/tilesets"} />
	            <meta property="og:url" content={"https://www.unlimited-tiles.com/" + detectedLanguage + "/" + game + "/tilesets"} />
	            <meta property="og:image" content="https://www.unlimited-tiles.com/banner2.png" />
	            <meta property="og:title" content={"Unlimited Tiles - " + gameToUpper({game}) + "- Available Tilesets"} />
	        </Helmet>


	        <div className="mainPart">
		        <Container>
		            <GoogleAd type="tilesetsTop"/>


		        </Container>
		    </div>


			{ badgesJson["gamesWithBadges"].includes(game)
			    ? <Container>
			        <div className="badgesTilesets">
			            <Badges  game={"all-" + game}/>
			            <p><center><a href="/badges">See all your badges</a></center></p>
			        </div>
				</Container>
				: <></>

			}

		    <div className="mainPart">
                <Container>
					{
		                badgesJson["gamesWithBadges"].includes(game)
		                ? <></>
		                : <h1>{bigName(game)}</h1>
		            }
					<h2>
		                <img className="biggerStar" src={"/images/tilesets/lines/star.png"}/> { countStars(game) + " / " + (3*tilesetsAvailable.length) }
		                    {
		                        game == "tiles"
		                        ? <Button style={{"margin-left": "20px", "margin-bottom": "8px"}} className="small-btn" variant="primary" onClick={() => copyLink({game,setLinkCopied})}>{t("copyLink")}</Button>
		                        : <></>
		                    }
		            </h2>
		            {
		                linkCopied
		                ? <center><h4>Your results copied to the clipboard :) </h4></center>
		                : <></>
		            }

		            {
		                <Row>
		                    {tilesets.map(tileset => ( <Tileset game={game} tileset={tileset["name"]} stars={getStarsTileset({tileset, game})}
		                        key={tileset["name"]} date={tileset["date"]} />))}
		                </Row>
		            }

		            <GoogleAd type="tilesetsBottom"/>
		        </Container>
				<br/>
	        </div>


			<Comments/>

			<Minigames/>
        </div>
      );

    return tilesetHtml
}
export default Tilesets;

