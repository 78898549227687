import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import '../../static/css/tilesets.css';



function Minigame({game, type}) {


	const { t } = useTranslation();

	var detectedLanguage = window.location.pathname.split('/')[1];
    if(detectedLanguage === "" || detectedLanguage.length > 2) {
    	detectedLanguage = "en";
    }


    function textToUpper({game}) {
        if(game === "same") {
            return "SAME GAME";
        }
        return game.toUpperCase();
	}

	function getUrl(detectedLanguage, game) {

		//return "/" + detectedLanguage + "/" + game + "/tilesets";
		if(game == "tiles") {
			return "/";
		} else {
			return "/" + detectedLanguage + "/" + game;
		}

	}

	function getAlt({game}) {
		var alts = {
			"tiles": "tiles game",
			"same": "same game",
			"lines": "color lines game",
			"pairs": "match pairs game",
			"infinite": "infinite tiles game",
			"spellbee": "spell bee game",
			"strands": "strands wordle game",
			"wordle": "infinite wordle game",
			"guesser": "number guesser game",
		};
		return alts[game];
	}

    return (
        <Col className='tilesetContainer'>
            <Card className='tilesetCard tilesetCardSmaller' >
                <a href={getUrl(detectedLanguage, game)}>
                 <Card.Img alt={getAlt({game})} variant="top" src={"/images/games/" + game + "/default.png"}  />

                <Card.Body>
                    <Card.Title>{textToUpper({game})}</Card.Title>
                    <Button href={getUrl(detectedLanguage, game)} className="small-btn" variant="primary" >PLAY</Button>


                </Card.Body>
                </a>
            </Card>
        </Col>
    );
}




const Minigames = ({random = false, game = "tiles"}) => {
	const { t } = useTranslation();
	var games = [ "shuzzle", "segments",  "swapoku", "neighbours", "pairs",  "same", "timed", "infinite", "lines", "unpaired", "tiles"];
    var promoted = ["shuzzle", "segments", "neighbours", "swapoku", "pairs", "same", "lines"];

	if(random) {

		const shuffled = promoted.filter(function(e) { return e !== game}).sort(() => 0.5 - Math.random());
        games = shuffled.slice(0, 2);

	}

	const gamesHtml =  (

		<Container>
	        {
	            random
	            ? <><br/><h2>You can also play one of our other games</h2>
	            </>
	            :  <><h2>{ t("otherGames") }</h2>
	            <p className="intro"><a href="/ourgames"><b>Click here </b> to read more about our tiles games. </a></p>
	            </>
	        }
	        {
	            <Row>
	                {games.map(game => ( <Minigame game={game} key={game} type="in"/>))}

	            </Row>
	        }
	    </Container>
    );

    return gamesHtml;
}
export default Minigames;
