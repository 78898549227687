import logo from './../../logo.svg';
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Instruction from './../Instruction';
import results from './../../static/json/results.json';
import levels_shuzzle from './../../static/json/levels_shuzzle.json';
import './../../static/css/minigames.css';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');



function Square({value, target, squareClass, onSquareClick, tileset, parts}) {
	var moreClasses = "";

	if(squareClass != "goodImage") {
		squareClass += " hiddenImage";
	}



    return (
        <td className={"minigameSquare minigameSquare" + parts + "parts " + squareClass }  onClick={onSquareClick}>
            <img className="shuzzleImage" src={"/images/games/shuzzle/" + tileset + "/d" + value + ".png"} alt="Tile"/>
        </td>
    );
}


function isValid(board, row, col, num, a, b) {
    const subgridRowStart = Math.floor(row / b) * b;
    const subgridColStart = Math.floor(col / a) * a;

    // Check the row
    for (let i = 0; i < a*b; i++) {
        if (board[row][i] === num) return false;
    }

    // Check the column
    for (let i = 0; i < a*b; i++) {
        if (board[i][col] === num) return false;
    }

    // Check the 2x3 subgrid
    for (let i = 0; i < b; i++) {
        for (let j = 0; j < a; j++) {
            if (board[subgridRowStart + i][subgridColStart + j] === num) return false;
        }
    }

    return true;
}

function fillBoard(board, row, col, a, b) {
    if (row === a*b) return true;
    if (col === a*b) return fillBoard(board, row + 1, 0, a, b);

    // Try numbers 1 to 6 in the current cell
    let numbers = [];
    for(let i = 1; i <= a*b; i++) {
        numbers.push(i);
    }

    numbers.sort(() => Math.random() - 0.5); // Shuffle numbers for randomness

    for (let num of numbers) {
        if (isValid(board, row, col, num, a, b)) {
            board[row][col] = num;
            if (fillBoard(board, row, col + 1, a, b)) {
                return true;
            }
            board[row][col] = 0; // Backtrack
        }
    }

    return false; // Trigger backtracking
}


function getGoal(parts) {
	var board = [];
	const R = parts, C = parts;
    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            board[i][j] = 10*(i+1)+j+1;
        }
    }
    return board;
}

function shuffle(array) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
}

function similar(a, b) {
	for(var i = 0; i < a.length; i++) {
		if(a[i] == b[i]) {
			return true;
		}
	}
	return false;
}

function getRandomStart(parts) {
	var original = [];
	var shuffled = [];
	for (let i = 1; i < parts+1; i++) {
        for (let j = 1; j < parts+1; j++) {
            original.push(10*i+j);
            shuffled.push(10*i+j);
        }
    }
	shuffle(shuffled);
	while(similar(original, shuffled)) {
		shuffle(shuffled);
	}

	var board = [], x = 0;
	const R = parts, C = parts;
    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            board[i][j] = shuffled[x];
            x++;
        }
    }
    return board;
}

function getEmptyBoard(parts) {
	const board = [];
	const R = parts, C = parts;
    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            board[i][j] = 0;
        }
    }
	return board;
}

function getParts(tileset) {
	if(levels_shuzzle["hard"].includes(Number(tileset))) {return 6;}
    else if(levels_shuzzle["medium"].includes(Number(tileset))) {return 5;}
    else { return 4;}
}

export default function Shuzzle({onTilesFinish, tileset}) {

    const parts = getParts(tileset);
    const [goal, setGoal] = useState(getGoal(parts));
    const [squares, setSquares] = useState(getRandomStart(parts));

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [longestCombo, setLongestCombo] =  useState(0);
    const [points, setPoints] =  useState(0);
    const [moves, setMoves] = useState(0);
    const [moveMessage, setMoveMessage] = useState("");
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState("");

    const [columnPrev, setColumnPrev] = useState(-1);
    const [rowPrev, setRowPrev] = useState(-1);
    const [clickedSquares, setClickedSquares] = useState(getEmptyBoard(parts));

	var timeStopped = false;


	function clearBoard() {

	    setSquares(getRandomStart(parts));
	    setClickedSquares(getEmptyBoard(parts));
	    setMoves(0);
	    setRowPrev(-1);
	    setColumnPrev(-1);
	}


	function checkPair(row1, col1, row2, col2) {

		var value1 = squares[row1][col1];
		var value2 = squares[row2][col2];

		squares[row1][col1] = value2;
		squares[row2][col2] = value1;
		setSquares(squares);

		clickedSquares[row1][col1] = 0;
		clickedSquares[row2][col2] = 0;
		setClickedSquares(clickedSquares);

		setColumnPrev(-1);
		setRowPrev(-1);

		return true;
	}

	function checkBoard() {
		var diff = 0;
		for(var i = 0; i < R; i++) {
			for(var j = 0; j < C; j++) {
				if(squares[i][j] != goal[i][j]) {
					diff += 1;
				}
			}
		}
		return diff;
	}


	function handleClick(row, column) {

		if(squares[row][column] == undefined ) {
            return;
        }

		if(squares[row][column] == goal[row][column] ) {
			setMoveMessage("This piece is in the right place. Don't mess it up!");
            return;
        }

        if(clickedSquares[row][column] == 1 || (column == columnPrev && row == rowPrev)) {

            setColumnPrev(-1);
            setRowPrev(-1);
            clickedSquares[row][column] = 0
            setClickedSquares(clickedSquares);
            setMoveMessage("");
            return;
        }



        if(columnPrev == -1) {


            clickedSquares[row][column] = 1;
            setClickedSquares(clickedSquares);
            setColumnPrev(column);
            setRowPrev(row);
            setMoveMessage("");

        } else {

			clickedSquares[row][column] = 1;
            setClickedSquares(clickedSquares);

            if(checkPair(rowPrev, columnPrev, row, column)) {
                setMoves(moves+1);

                setMoveMessage("");
                var diff = checkBoard();
                setPoints(diff);
                if(diff == 0) {
                    onTilesFinish(moves+1);
                }
            } 

        }

    }

	const R = parts;
	const C = parts;
	var rows = Array(R);
	var columns = Array(C);
	for(var i = 0; i < R; i++) {
		rows[i] = i;
	}
	for(var i = 0; i < C; i++) {
		columns[i] = i;
	}

	function lineCode(num) {
		var row = num;
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square tileset={tileset} key={C*num+column} value={squares[row][column]} target={goal[row][column]}
                        squareClass={ clickedSquares[row][column] == 1 ? 'chosenImage' :
                                      squares[row][column] == goal[row][column] ? 'goodImage' : '' }
						onSquareClick={() => handleClick(row,column)}
						parts = {parts} />

				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("moveInfoShuzzle");
	}


	const boardCode = (<div className="fullBoard">

			<Row>
				<h1 style={{"border":"3px solid black", "margin-bottom": "1px", "margin-top": "5px", "background-color": "#d3dfec"}}>{"LEVEL " + tileset.toUpperCase()}</h1>
                <Row className="instruction">
                    <p className="instructionText"> {getInstruction()} </p>
                </Row>
				<div style={{"height": "30px"}}>
					<div style={{"background-color": "red", "color": "white", "max-width": "360px", "margin": "auto",
					"margin-bottom": "10px", "border-radius": "3px"}}>
					{ moveMessage }
					</div>
				</div>

				<Col sm={9} className="gameBoardCol">
					<Table className="minigameGameBoard shuzzle"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1">{ "IMAGE" }<br/><img src={"/images/games/shuzzle/" + tileset + "x.png"} /></div>

					<div className="col1">{  }<br/><h1>{ }</h1></div>
					<div className="col1">{ "SWAPS" }<br/><h1>{ moves }</h1></div>
					<br/>
					<Button variant="success" className="small-btn" onClick={() =>  clearBoard()}>RESET</Button>
					</Row>
				</Col>
			</Row>


	    </div>
	);

    return boardCode;
}

