import general from '../static/json/general.json';
import results from '../static/json/results.json';
import levels_lines from '../static/json/levels_lines.json';


export function getLevels(game) {
	var ts, tilesets = [];
	console.log(game);
	const tilesgames = ["tiles", "tilesbig", "pairs", "infinite", "timed", "unpaired"];
	var listName = tilesgames.includes(game) ? "waiting" : game;
	var levels = general[listName].map(tset => tset.name);
	return levels;
}
