import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet';

import Minigames from './games/Minigames';


function Test() {
	const { t } = useTranslation();

    return (
        <div className="mainPart">
        <Helmet>
        	            <title>All Unlimited Tiles Games</title>
        	            <meta name="description" content="Choose your favorite unlimited tiles game" />
        	            <link rel="canonical" href={"https://www.unlimited-tiles.com/games"} />
        	            <meta property="og:url" content={"https://www.unlimited-tiles.com/games"} />
        	            <meta property="og:image" content="https://www.unlimited-tiles.com/banner.png" />
        	            <meta property="og:title" content="Unlimited Tiles - Our Tile Games" />
        	        </Helmet>
        <Container>
            <Row>

                <Minigames/>
            </Row>

        </Container>
        </div>
    );
}
export default Test;