import logo from './../../logo.svg';
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useEffect } from 'react';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import Instruction from './../Instruction';
import general from './../../static/json/general.json';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');

function Square({value, isCurrent, onSquareClick, tileset}) {
	var classes = isCurrent ? "square currentSquare" : "square";
	var tiles = "";

	function getImage(num) {
		var type = ["a", "b", "c"];
		if(value[num] > 0) {
			return <div className="image"><img src={"/images/tilesets/" + tileset + "/" + type[num] + value[num] + ".png"} alt="Tile"/></div>;
		} else {
			return "";
		}
	}

	function getBackground() {
		return <div className="image"><img src={"/images/tilesets/" + tileset + "/background.png"} alt="X"/></div>;

	}

    return (
        <td className={classes} onClick={onSquareClick}>
			{ getBackground() }
            { getImage(0) }
            { getImage(1) }
            { getImage(2) }

        </td>
    );
}

function getRandomStart() {

	var board = Array(30).fill([0,0,0]);
	var layer1 = [1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3];
    var layer2 = [1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3];
    var layer3 = [1,1,1,1,1,1,2,2,2,2,2,2,3,3,3,3,3,3,4,4,4,4,4,4,5,5,5,5,5,5];

	layer1.sort(() => Math.random() - 0.5);
    layer2.sort(() => Math.random() - 0.5);
    layer3.sort(() => Math.random() - 0.5);
	layer1.sort(() => Math.random() - 0.5);
    layer2.sort(() => Math.random() - 0.5);
    layer3.sort(() => Math.random() - 0.5);
	for(var i = 0; i < 30; i++) {
		board[i] = [layer1[i],layer2[i],layer3[i]];
	}
	return board;
}

export default function Tiles({onTilesFinish, tileset}) {
	const [lastPos, setLastPos] = useState(-1);
    const [squares, setSquares] = useState(getRandomStart);

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [longestCombo, setLongestCombo] = useState(0);
    const [tilesDeleted, setTilesDeleted] = useState(0);

    const [time, setTime] = useState(100000);
	const [isRunning, setIsRunning] = useState(false); // To track whether the timer is running
	var finished = false;

    useEffect(() => {
        let interval = null;
        if (isRunning) {
            interval = setInterval(() => {
                setTime(prevTime => prevTime - 10);
            }, 10);
        }
        return () => clearInterval(interval);
    }, [isRunning]); // Only starts interval when isRunning is true




	function handleClick(clicked) {

		setIsRunning(true);

		if (time <= 0 || clicked === lastPos || (squares[clicked][0] === 0 && squares[clicked][1] === 0 && squares[clicked][2] === 0)) {
            return;
        }

        var nextSquares = squares.slice();
        for(var i = 0; i < 30; i++) {
            if(nextSquares[i][0] == 0 && nextSquares[i][1] == 0 && nextSquares[i][2] == 0) {
                nextSquares[i][0] = 1 + Math.floor(Math.random()*3);
                nextSquares[i][1] = 1 + Math.floor(Math.random()*3);
                nextSquares[i][2] = 1 + Math.floor(Math.random()*5);
            }
        }

        if (lastPos === -1) {
            setLastPos(clicked);
        } else {
            var correct = 0;
            var newTilesDeleted = tilesDeleted;
            for(var i = 0; i < 3; i++) {
                if(squares[clicked][i] === squares[lastPos][i] && squares[clicked][i] != 0) {
                    nextSquares[clicked][i] = 0;
                    nextSquares[lastPos][i] = 0;
                    correct = 1;
                    newTilesDeleted += 2;
                }
            }

            if(correct) {
                setTilesDeleted(newTilesDeleted);
                if(nextSquares[clicked][0] === 0 && nextSquares[clicked][1] === 0 && nextSquares[clicked][2] === 0) {
                    setLastPos(-1);
                } else {
                    setLastPos(clicked);
                }
                var newCurrentCombo = currentCombo + 1;
                var newLongestCombo = longestCombo;
                setCurrentCombo(newCurrentCombo);
                if(newCurrentCombo > longestCombo) {
                    newLongestCombo = newCurrentCombo;
                    setLongestCombo(newLongestCombo);
                }
            } else {
                setCurrentCombo(0);
            }

        }
        setSquares(nextSquares);

    }

	const rows = [0,1,2,3,4,5];
	const columns = [0,1,2,3,4];

	function lineCode(num) {
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square tileset={tileset} key={5*num+column} value={squares[5*num+column]} isCurrent={lastPos === 5*num+column}
					onSquareClick={() => handleClick(5*num+column)} />
				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
        const instruction = [t("moveInfo1"), t("moveInfo2")];
	    if(lastPos === -1) {
	        return instruction[0];
	    } else {
	        return instruction[1];
	    }
	}

	function showTime(time) {
		if(time == 0) {
            onTilesFinish(longestCombo);

        }
		if (time > 0) { return (time/1000).toFixed(1); }
		else { return 0;}
	}


	const boardCode = (<div className="fullBoard">

			<Row>
				<Row className="instruction">
                    <div className="col2"><p className="instructionText"> {getInstruction()} </p></div>
                    <div className="col3"> <Instruction/></div>
                </Row>

				<Col sm={9} className="gameBoardCol">
					<Table className="gameBoard"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1">{ t("currentCombo") }<br/><h1>{ currentCombo }</h1></div>
					<div className="col1">{ t("longestCombo") }<br/><h1>{ longestCombo }</h1></div>
					<div className="col1">{ "Time left" }<br/><h1>{ showTime(time) }</h1></div>
					<br/>
					<Button variant="success" className="small-btn" onClick={() =>  window.location.reload()}>RESTART</Button>
					</Row>
				</Col>
			</Row>

	    </div>
	);

    return boardCode;
}

