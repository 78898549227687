import general from '../static/json/general.json';
import results from '../static/json/results.json';
import levels_lines from '../static/json/levels_lines.json';
import levels_shuzzle from '../static/json/levels_shuzzle.json';


export function getLevels(game) {
	var ts, tilesets = [];
	const tilesgames = ["tiles", "tilesbig", "pairs", "infinite", "timed", "unpaired"];
	var listName = tilesgames.includes(game) ? "waiting" : game;
	var levels = general[listName].map(tset => tset.name);
	return levels;
}

function getAllLevels({allgames}) {
	var levels = {};
	for(var i = 0; i < allgames.length; i++) {
		var game = allgames[i];
		levels[game] = getLevels(game);
	}
	return levels;
}


export function getStarsAmount(tileset, game, points) {

	if(points == undefined || points == null) {
		return 0;
	}
	const fourLayersSets = general["fourlayers"];
	var three_stars, two_stars;

	if(game == "tiles" && fourLayersSets.includes(tileset)) {game = "fourlayers";}
	if(game == "lines") {
		three_stars = levels_lines[tileset]["best"];
		two_stars = three_stars + 3;
	} else if(game == "shuzzle") {
		var difficulty = "easy";
		if(levels_shuzzle["hard"].includes(Number(tileset))) {difficulty = "hard";}
        if(levels_shuzzle["medium"].includes(Number(tileset))) {difficulty = "medium";}
        three_stars = results[game][difficulty]["three"];
        two_stars = results[game][difficulty]["two"];
	} else {
		if(results[game]["all"]) {
			three_stars = results[game]["three"];
	        two_stars = results[game]["two"];
        } else {
            three_stars = results[game][tileset]["three"];
            two_stars = results[game][tileset]["two"];
        }
	}

	var rule = results[game]["rule"];

	if(rule == "bigger") {
		if(points >= three_stars) {return 3;}
		if(points >= two_stars) {return 2;}
		return 1;
	} else {
		if(points <= three_stars) {return 3;}
		if(points <= two_stars) {return 2;}
		return 1;
	}
}

export function getStars(tileset, game) {
	var best = localStorage.getItem(game + "-" + tileset + "-best");
	var starsAmount = getStarsAmount(tileset, game, best);

	localStorage.setItem(game + "-" + tileset + "-stars", starsAmount);
	return starsAmount;
}

function countAllStars({allgames, levels}) {
	for(var i = 0; i < allgames.length; i++) {
		var game = allgames[i];
		var gameLevels = levels[game];
		for(var j = 0; j < gameLevels.length; j++) {
			var tileset = gameLevels[j];
			getStars(tileset, game);
		}
	}
}


export function countStars() {

	var allgames = ["tiles", "pairs", "infinite", "timed", "unpaired", "same", "lines"];
	var levels = getAllLevels({allgames});

	countAllStars({allgames, levels});
}